@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothaPro400.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothaPro500.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothaPro600.woff2") format("woff2");
  font-style: normal;
  font-weight: 600;
}

body {
  font-family: "Gotham Pro", arial, serif !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
input,
optgroup,
select,
textarea {
  font-family: "Gotham Pro", arial, serif !important;
  letter-spacing: 0 !important;
}

.loader-div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(10px);
}

.pt100 {
	padding-top: 100px !important;
}

.mb15 {
	margin-bottom: 15px !important;
}

/* Login Page Css */

.password-eye {
  padding: 15px 15px 15px 10px !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  margin: auto;
  cursor: pointer;
}
.link {
  cursor: pointer;
}

/* Signup Page Css */

.signup-top-padding {
  padding: 5vh !important;
}

/* dashboard Page Css */

.dashboard-pannel {
  background-color: #e9ecef;
  border-radius: 10px;
  min-height: 60vh;
  padding: 10px 20px 20px 20px;
  margin-top: 50px;
}

.custom-breadcrum ol {
  margin: 0 3rem 1rem !important;
  background-color: transparent !important;
  justify-content: flex-end;
}

.charts-sec {
  margin-top: 25px;
}

.margin_auto {
  margin: auto;
}

@media only screen and (max-width: 1220px) {
  .custom-breadcrum ol {
    margin: 0 0 1rem !important;
  }
}

/* User Page */
.pad-2 {
  padding: 2rem;
}

/* Product Page  */
.uniqueName th,
td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.product-head {
  font-size: 1.825em;
}

.justify-product {
  align-items: center;
  justify-content: space-between;
}

/* Modal Css */
.btn-row-modal {
  justify-content: flex-end;
  margin-top: 15px;
}

/* common css*/
.uidash {
  background-color: whitesmoke;
  border-radius: 10px;
}
.full-width {
  width: 100%;
}
.text-end {
  text-align: end;
}
.cursor-pointer {
  cursor: pointer !important;
}

.width_80 {
  width: 80%;
}
.reset-modal-btn {
  justify-content: center !important;
  margin: 10px;
}
.nice_date_input_custom {
  padding: 5px 10px;
  border: 1px solid lightgrey;
}
.date-range {
  color: #111;
}
.date-range span {
  margin-left: 5px;
  margin-right: 5px;
}

.nice-dates-navigation,
.nice-dates-day {
  color: #111;
}
.nice-dates-popover {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 2px;
  max-width: 400px !important;
  transition: none;
}
.align-center {
  align-items: center !important;
}
.add-receive-btn {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}
.receive-head {
  color: #111;
}
.justify-flex-end {
  display: flex;
  justify-content: flex-end;
}
.width-100 {
  width: 100%;
}
.h-24 {
  height: 24rem;
}

.example-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.grid-wrapper {
  flex: 1 1 0px;
}

#myGrid {
  height: 100%;
  width: 100%;
}
.pt-6 {
  padding-top: 6rem;
}
.sw_form_control {
  width: 100% !important;
}
.sw_form_control label.MuiInputLabel-formControl {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #111 !important;
}
.sw_form_control .MuiInput-formControl {
  margin-top: 24px !important;
}
.sw_radiogroup .MuiFormControlLabel-root .MuiIconButton-colorSecondary {
  padding: 0px 5px 0px 9px !important;
}
.sw_Checkboxgroup .MuiFormControlLabel-root .MuiIconButton-colorPrimary {
  padding: 0px 5px 0px 9px !important;
}
.sw_radiogroup .MuiFormControlLabel-label,
.sw_Checkboxgroup .MuiFormControlLabel-label,
.MuiFormControlLabel-label {
  font-size: 12px !important;
}
.sw_radiogroup label {
  margin-bottom: 2px !important;
}
.sw_radiogroup legend.MuiFormLabel-root {
  margin-bottom: 3px !important;
}
.marginTop10 {
  margin-top: 10px !important;
}
.sw_card .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 5px 12px !important;
}
.sw_card .MuiGrid-spacing-xs-3 {
  margin-top: 10px !important;
}
.sw_card .MuiCardHeader-root {
  border-bottom: 1px solid #dedede;
  padding: 10px 14px !important;
}
.sw_card .MuiCardHeader-root .MuiCardHeader-title {
  font-size: 15px !important;
}
.sw_overlay_text {
  position: relative;
}
.sw_overlay_text .sw_pack_size {
  position: absolute;
  right: 0px;
  bottom: 5px;
  font-size: 12px;
}
.sw_overlay_text .MuiFormControl-fullWidth {
  width: 75%;
}
.marginTop20 {
  margin-top: 20px !important;
}
.sw_fullWidth_hidden {
  width: 100% !important;
  overflow: hidden;
}
.sw_text_label {
  font-size: 20px !important;
}
.navbar .navbar-brand {
  font-size: 24px !important;
  font-weight: bold !important;
  padding-bottom: 0 !important;
  text-transform: initial !important;
}
.index-page .navbar,
.navbar.navbar-transparent {
	padding-top: 10px !important;
	padding-left: 0px !important;
}
.logo_card {
	width: 100%;
	height: auto;
	margin: 5px 0px 0px 0px;
	position: relative;
}
.logo_card .logo {
	width: 75px;
	height: auto;
	float: left;
	position: relative;
}
.logo_card .logo img {
	width: 100%;
	height: auto;
}
.logo_card .logo_desc {
	width: calc(100% - 75px);
	padding-left: 85px;
	padding-top: 5px;
}
.logo_card .logo_desc .title {
	padding-top: 5px;
	line-height: 1;
}
.logo_card .logo_desc .title a,
.logo_card .logo_desc .title a:hover {
	font-size: 24px;
	font-weight: 700;
	color: #0055a2;
	text-decoration: none;
}
.index-page .navbar .logo_card .logo_desc .title a,
.index-page .navbar .logo_card .logo_desc .title a:hover {
	color: #fff;
}
.index-page .navbar-transparent.navbar .logo_card .logo_desc .title a,
.index-page .navbar-transparent.navbar .logo_card .logo_desc .title a:hover {
	color: #0055a2;
}
.sticky .logo_card .logo_desc .title a,
.sticky .logo_card .logo_desc .title a:hover {
	color: #fff;
}
.logo_card .logo_desc .name {
	padding-top: 1px;
	line-height: 1;
}
.logo_card .logo_desc .name a,
.logo_card .logo_desc .name a:hover {
	font-size: 16px;
	font-weight: 500;
	color: #0055a2;
	text-decoration: none;
}
.index-page .navbar .logo_card .logo_desc .name a,
.index-page .navbar .logo_card .logo_desc .name a:hover {
	color: #fff;
}
.index-page .navbar-transparent.navbar .logo_card .logo_desc .name a,
.index-page .navbar-transparent.navbar .logo_card .logo_desc .name a:hover {
	color: #0055a2;
}
.sticky .logo_card .logo_desc .name a,
.sticky .logo_card .logo_desc .name a:hover {
	color: #fff;
}

.sw_sub_title {
  width: 100%;
  float: left;
  color: #fff;
  position: absolute;
  left: 85px;
  bottom: 3px;
}
.brand .h1-seo {
  text-transform: initial !important;
}
.MuiTableCell-root {
  padding: 4px 5px !important;
  font-size: 12px !important;
}
.MuiIconButton-root {
  padding: 3px !important;
}

.classDispenserLevel {
  border: 1px solid #ccc;
  font-size: 95px;
  width: 100px;
  height: 100px;
  display: inline-block;
  text-align: center;
  line-height: 100px;
}

.MuiInputBase-input {
  font-size: 12px !important;
}
.MuiMenuItem-root {
  font-size: 12px !important;
}
.sw_fontSize {
	font-size: 12px !important;
}
.makeStyles-tableContainer-3 {
  padding: 1rem 2rem !important;
}
.sw_makeStyles_tableContainer {
  color: black;
  padding: 1rem !important;
  border-radius: 10px;
  background-color: whitesmoke;
}
.MuiCardContent-root {
  padding: 10px !important;
}
.sw_list {
}
.sw_list li {
  padding: 0px;
}
.sw_list li .MuiListItemText-root {
  margin-top: 0px !important;
}
.sw_list li .MuiTypography-root {
  font-size: 12px !important;
}
.btn,
.navbar .navbar-nav > a.btn {
  font-size: 12px !important;
}
.sw_page_heading {
  width: 100%;
  display: inline-block;
}
.sw_heading_title {
  float: left;
}
.sw_heading_title,
.sw_heading_title_inner {
  font-size: 16px;
  font-weight: 500;
  font-family: "Gotham Pro", arial, serif !important;
  text-transform: uppercase;
}
.sw_heading_title1 {
	float: left;
	margin-top: 12px;
  }
  .sw_heading_title1,
  .sw_heading_title_inner {
	font-size: 16px;
	font-weight: 500;
	font-family: "Gotham Pro", arial, serif !important;
	text-transform: uppercase;
  }
.sw_btn_control {
}
.sw_btn_control .btn {
  padding: 5px 10px;

}
.sw_btn_control2 .btn {
	padding: 10px 10px;
	color: rgba(69, 68, 68, 0.87);
	box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
	background-color: #e0e0e0;
	font-weight: bold;
	font-size: 12px !important;
	margin: 4px 1px !important;
  }



.MuiToolbar-regular {
  min-height: 35px !important;
}
.stock {
  background-color: #339933;
  color: #fff !important;
}
.overstocked {
  background-color: #002060;
  color: #fff !important;
}
.understocked {
  background-color: #cdad23;
  color: #fff !important;
}
.stockedout {
  background-color: #c00000;
  color: #fff !important;
}
.sw_filter_card {
  margin-bottom: 10px;
}
.sw_filter_card .sw_filterCardContent {
  padding: 10px !important;
}
.sw_item_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sw_item_list li {
  float: left;
  margin-right: 20px;
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: 12px;
}
.stat-cell {
  overflow: hidden;
  padding: 8px;
  position: relative;
  background: #a1a194;
  width: 100%;
  color: #fff;
}
.bgPrimary {
	background: #0055a2 !important;
}
.bgInfo {
	background: #17a2b8 !important;
}
.bgWarning {
	background: #ffc107 !important;
}
.stat-cell .bg-icon {
  bottom: 0;
  font-size: 70px;
  height: 82px;
  line-height: 100px;
  position: absolute;
  right: 0;
  text-align: center;
  width: 120px;
  color: rgba(0, 0, 0, 0.08);
}
.text-xlg {
  font-size: 2em;
}
.text-bg {
  font-size: 17px;
}
.sw_panel_body {
  padding: 2px;
  margin-top: 4px;
  overflow: hidden;
}
.progress {
  border-radius: 10px;
  margin-bottom: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  height: 30px;
  overflow: hidden;
}
.progress-bar {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
  color: #ffffff;
  float: left;
  font-size: 12px;
  height: 100%;
  line-height: 20px;
  text-align: center;
  transition: width 0.6s ease 0s;
}
.progress_text_box {
  width: 100%;
  overflow: hidden;
}
.text_box {
  width: 50%;
  height: auto;
  overflow: hidden;
  float: left;
}

.bgcolor1 {
  background-color: #c0504d;
}
.bgcolor2 {
  background-color: #4f81bd;
}
.bgcolor3 {
  background-color: #57bfb8;
}
.bgcolor4 {
  background-color: #ac92c2;
}
.textcolor1 {
  color: #4f81bd;
}
.textcolor2 {
  color: #c0504d;
  text-align: right;
}
.textcolor3 {
  color: #ac92c2;
}
.textcolor4 {
  color: #57bfb8;
  text-align: right;
}
.sw_head_text {
  font-size: 18px;
  font-weight: 500;
}
.human_list_card {
}
.human_list {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.human_list li {
  float: left;
  margin-right: 20px;
}
.human_list li img {
  width: 24px;
}
.per_badge {
  float: right;
  background: #777;
  padding: 15px 15px;
  font-size: 25px;
}

/*==========mylegend_area============*/
.mylegend_area {
  width: 100%;
  overflow: hidden;
  float: left;
  clear: both;
  margin-bottom: 10px;
  margin-left: 10px;
}
.legend_group {
  width: 100%;
  background: #f0eded;
  padding-left: 5px;
  clear: both;
  height: 20px;
}
.my_legend {
  width: 16%;
  float: left;
  text-align: left;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  
}
.my_legend .mylegend_color {
  width: 100%;
  height: 20px;
}
.my_legend .mylegend_label {
  width: 100%;
}
.my_legend .mylegend_val {
  width: 100%;
}
@media only screen and (min-width: 480px) and (max-width: 800px) {
  .my_legend {
    text-align: center;
  }
}
@media (max-width: 479px) {
  .my_legend {
    width: 100%;
  }
}

.tabulator {
  font-size: 12px !important;
}
.MuiFormLabel-root {
  font-size: 12px !important;
}
.sw_home_img {
  width: 100%;
}
.sw_home_img img {
  width: 100%;
  height: auto;
}
.sw_home_content {
  padding-top: 30px;
}
.sw_home_content p {
  text-align: justify !important;
  font-size: 22px;
  line-height: 35px;
}
.bg_white,
.bg_white:hover {
  background: #fff;
}
.login-page .card-login.card-plain .input-group.no-border .form-control {
  background-color: rgba(255, 255, 255, 0.7);
  color: #2c2c2c;
}
.login-page .card-login.card-plain .input-group.no-border .input-group-text {
  background-color: #2ca8ff;
  color: #fff;
}
.sw_login_padd {
  padding: 15px 8px 15px 14px !important;
}
input.form-control.placeholder_color::placeholder {
  color: #2c2c2c !important;
  opacity: 1;
}
input.form-control.placeholder_color:-ms-input-placeholder {
  color: #2c2c2c !important;
}
input.form-control.placeholder_color::-ms-input-placeholder {
  color: #2c2c2c !important;
}
.login-page .card-login.card-plain .form-group.no-border .form-control:focus,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .form-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:focus,
.login-page .card-login.card-plain .input-group.no-border .form-control:active,
.login-page .card-login.card-plain .input-group.no-border .form-control:active {
  background-color: rgba(255, 255, 255, 0.7);
  color: #2c2c2c;
}
.login-page .card-login.card-plain .form-group.no-border.input-group-focus .input-group-text,
.login-page .card-login.card-plain .input-group.no-border.input-group-focus .input-group-text {
  background-color: #2ca8ff;
  color: #fff;
}

.btn-primary {
	background-color: #2CA8FF;
	color: #FFFFFF;
}
.btn-primary:hover {
	background-color: #4bb5ff;
	color: #FFFFFF;
}

.btn-primary.disabled, 
.btn-primary.disabled:hover, 
.btn-primary.disabled:focus, 
.btn-primary.disabled.focus, 
.btn-primary.disabled:active, 
.btn-primary.disabled.active, 
.btn-primary:disabled, 
.btn-primary:disabled:hover, 
.btn-primary:disabled:focus, 
.btn-primary:disabled.focus, 
.btn-primary:disabled:active, 
.btn-primary:disabled.active, 
.btn-primary[disabled], 
.btn-primary[disabled]:hover, 
.btn-primary[disabled]:focus, 
.btn-primary[disabled].focus, 
.btn-primary[disabled]:active, 
.btn-primary[disabled].active, 
fieldset[disabled] .btn-primary, 
fieldset[disabled] .btn-primary:hover, 
fieldset[disabled] .btn-primary:focus, 
fieldset[disabled] .btn-primary.focus, 
fieldset[disabled] .btn-primary:active, 
fieldset[disabled] .btn-primary.active,
.btn-primary.disabled:active:hover,
.btn-primary.disabled:hover {
	background-color: #888888;
	border-color: #888888;
}
.btn:disabled:hover{
	opacity: .65;
}
.btn-primary:hover, 
.btn-primary:focus, 
.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.btn-primary:active:hover, .btn-primary.active:hover, 
.show > .btn-primary.dropdown-toggle, 
.show > .btn-primary.dropdown-toggle:focus, 
.show > .btn-primary.dropdown-toggle:hover {
	background-color: #2CA8FF;
	color: #FFFFFF;
	box-shadow: none;
}
button.btn.sw_icons {
  padding: 4px 8px;
  min-width: auto;
}
button.btn.sw_icons.sw_group_btn {
	padding: 4px 8px;
	min-width: auto;
	align-items: center;
	display: flex;
	float: left;
}
button.btn.sw_icons i {
  font-size: 20px;
}
button.btn .sw_text {
	margin-left: 8px;
	padding: 0;
	line-height: 15px;
}
button.MuiButton-root.sw_icons {
  padding: 8px 8px;
  min-width: auto;
}
button.MuiButton-root.sw_icons i {
  font-size: 20px;
}
button.MuiButton-root {
	padding: 6px 10px;
	font-size: 12px;
}
.sw_card .MuiCardContent-root .MuiBox-root {
  padding: 10px 0px !important;
}
.tabulator-cell .MuiInputBase-input {
  padding: 0px 0 7px;
}
.tabulator-cell .MuiSvgIcon-root {
  font-size: 18px;
}
.tabulator-cell .MuiIconButton-root {
  padding: 0px !important;
}
/*.sw_makeStyles_tableContainer .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 6px 12px;
}*/
.sw_makeStyles_tableContainer label + .MuiInput-formControl {
  margin-top: 10px;
}
.sw_active_patient {
  margin-top: 12px !important;
}
/*==Start Preloader==*/
.sw_relative {
  position: relative;
}
.sw_preloader_center {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	height: 70px;
	width: 100%;
	background: rgba(555, 555, 555, 0.9);
	z-index: 9999;
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
}

.swPreloaderCenter {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	/* height: 100%; */
	height: 70px;
	width: 100%;
	/* background: rgba(555, 555, 555, 0.9); */
	z-index: 9999;
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	transform: translateY(-50%);
}
.sw_preloader_area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /*height: 100vh;*/
  height: 100%;
  width: 100%;
  background: rgba(555, 555, 555, 0.9);
  z-index: 9999;
}
.sw_preloader {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.lds-dual-ring {
  display: inline-block;
  width: 70px;
  height: 70px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 70px;
  height: 70px;
  margin: 1px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 6px solid #3f51b5;
  border-color: #3f51b5 transparent #3f51b5 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*==End Preloader==*/

/*==Start Preloader global==*/
.sw_preloader_area_global {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(555, 555, 555, 0.9);
  z-index: 9999;
}
.sw_preloader_global {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.lds-dual-ring-global {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring-global:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 1px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 3px solid #3f51b5;
  border-color: #3f51b5 transparent #3f51b5 transparent;
  animation: lds-dual-ring-global 1.2s linear infinite;
}

@keyframes lds-dual-ring-global {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*==End Preloader global==*/

.sw_ToggleButtonGroup button.MuiToggleButton-root {
  padding: 6px;
}
.sw_ToggleButtonGroup{
	margin-bottom: 7px;
}
.sw_ToggleButtonGroup2 button.MuiToggleButton-root {
	padding: 6px 73px 6px 73px;
}
.sw_ToggleButtonGroup2{
	margin-bottom: 7px;
}
.sw_ToggleButtonGroup button.MuiToggleButton-root {
	font-size: 12px;
	font-family: "Gotham Pro", arial, serif !important;
	font-weight: 400;
	text-transform: capitalize;
}
.totalbrand{
  padding: 0;
  margin: 0;
  font-weight: bold;
}
.sw_search {
  float: left;
  width: 200px;
  margin-left: 10px;
}
.sw_search .MuiFormControl-root {
  width: 100%;
}
.sw_search .MuiInput-formControl {
  margin-top: 12px;
}
.card-signup.card select.form-control option {
  color: #000;
}
.dnone {
  display: none !important;
}
.footer ul li a {
  text-transform: initial;
}

/*======Home Page css======*/
.sw_section {
	background-repeat: repeat;
	background-size: auto;
	background-position: center;
	padding: 70px 35px;
	position: relative;
	width: 100%;
	height: auto;
}
.image_card {
	width: 100%;
	height: auto;
  padding-top: 3%;
}
.image_card img {
	width: 100%;
	height: auto;	
}
.section_desc {}
.section_desc .title {
	color: #0055a2;
	font-size: 46px;
	line-height: 60px;
	padding: 0;
}
.section_desc .desc {
	font-size: 20px;
}
.section_desc p{

}
.news_card {
	background: #fff;
	overflow: hidden;
	border-radius: 6px;
}
.sw_item_card {
	padding: 20px 20px;

}
.sw_item_card .card_desc {}
.sw_item_card .card_desc .title {
	padding-top: 0px;
	margin-bottom: 20px;
	font-size: 24px;
}
.sw_item_card .card_desc .title,
.sw_item_card .card_desc .title a {
	color: #0055a2;
	text-decoration: none;
}
.sw_item_card .card_desc .title a:hover {
	text-decoration: none;
}
.sw_item_card .card_desc p {}
.sw_item_card .card_desc .more_btn {
	background-color: #fff;
	color: #0055a2;
	font-weight: 700;
	text-decoration: underline;
}
.sw_item_card .card_desc .more_btn:hover {
	text-decoration: none;
}

.item_card {}
.item_card .sw_icon {
	width: 90px;
	height: 90px;
	margin-bottom: 20px;
}
.item_card .sw_icon img {
	width: 100%;
	height: auto;
}
.item_card .item_desc .title {
	font-size: 20px;
	padding: 0;
	margin-bottom: 10px;
}
.item_card .item_desc .title a {
	color: #0055a2;
}
.item_card .item_desc .title a:hover {
	text-decoration: none;
}
.item_card .item_desc p {
	font-size: 14px;
}

.sw_section.black-overlay::before {
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	background: #fff;
	opacity: 0.9;
}
@-webkit-keyframes play-pulse {
    0% {
		-webkit-transform: scale(0.65);
			-moz-transform: scale(0.65);
		    -ms-transform: scale(0.65);
		     -o-transform: scale(0.65);
		        transform: scale(0.65);
    }
    100% {
		-webkit-transform: scale(1.5);
			-moz-transform: scale(1.5);
		    -ms-transform: scale(1.5);
		     -o-transform: scale(1.5);
		        transform: scale(1.5);
				opacity: 0;
    }
}
@keyframes play-pulse {
    0% {
		-webkit-transform: scale(0.65);
			-moz-transform: scale(0.65);
		    -ms-transform: scale(0.65);
		     -o-transform: scale(0.65);
		        transform: scale(0.65);
    }
    100% {
		-webkit-transform: scale(1.5);
			-moz-transform: scale(1.5);
		    -ms-transform: scale(1.5);
		     -o-transform: scale(1.5);
		        transform: scale(1.5);
				opacity: 0;
    }
}

.video_card {
	width: 100%;
	/* height: 360px; */
	height: 400px;
	position: relative;
	overflow: hidden;
}
.video_card img {
	width: 100%;
	height: 100%;
}
.video_card.black-overlay::before {
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	background: #fff;
	opacity: 0.6;
}
.play_card {
	padding: 150px 0px;
}
.play-btn {
	position: absolute;
	background: #0055a2;
	width: 70px;
	height: 70px;
	line-height: 65px;
	text-align: center;
	font-size: 30px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	-webkit-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid #0055a2;
	cursor: pointer;
	-webkit-transition: all 0.5s ease-in-out 0s;
	transition: all 0.5s ease-in-out 0s;
}
.play-btn::before, .play-btn::after {
	position: absolute;
	content: "";
	border: 1px solid #0055a2;
	display: block;
	top: -10px;
	right: -10px;
	bottom: -10px;
	left: -10px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	-webkit-animation: play-pulse 1.5s linear infinite;
	animation: play-pulse 1.5s linear infinite;
}
.play-btn::after {
	animation-delay: 0.5s;
}
a.play-btn {
	color: #fff;
}
.app_btn_card {
	width: 100%;
	margin-top: 40px;
}
.app_btn_card .app_icon {
	width: 160px;
}
.app_btn_card .app_icon img {
	width: 100%;
}

.video_iframe_modal {}
.video_iframe_modal .modal-content .modal-body {
	padding-top: 0px;
	padding-right: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
}
.video_iframe {
	width: 100%;
	height: 450px;
	position: relative;
}
.video_iframe iframe {
	width: 100%;
	height: 450px;
}
.video_iframe .sw_close_btn {
	float: right;
	position: absolute;
	top: -35px;
	right: 0;
	z-index: 999999999;
}
.video_iframe .sw_close_btn i {
	font-size: 26px;
	color: #fa4d65;
}

@media only screen and (min-width: 300px) and (max-width: 550px) {
	.video_card {
		height: 250px;
	}	
}

.card-login a.link,
.card-login a.link:hover {
	color: #0055a2;
}
.footer {
	padding: 24px 0;
	background-color: #2c2c2c;
}
.footer .copyright a {
	color: #FFFFFF;
}
ul.navbar-nav li.nav-item a.nav-link {
	color: #0055a2;
}
.goto_btn1 {
	text-transform: uppercase;
	font-size: 14px !important;
	font-weight: 700;

}
.goto_btn2 {
	text-transform: uppercase;
	font-size: 14px !important;
	font-weight: 700;
  margin-top: 1%;
}
.goto_btn3 {

	text-transform: uppercase;
	font-size: 14px !important;
	font-weight: 700;
  margin-top: 11%;

}
.button_link{

}
.Map_Style{
  overflow: hidden !important;
  width: 100% !important;
  height: 620px !important;
}

/*====about/Contact Page====*/
.mb-30 {
	margin-bottom: 30px;
}
.inner-section {
	padding: 150px 35px 60px 35px;
}
.contact-section {
	padding: 150px 35px;
}
.contact-form h3 {
	margin-bottom: 10px;
}
.contact-section .contact-form input.form-control {
	height: 45px;
}
.contact-section .contact-form input.form-control, 
.contact-section .contact-form textarea.form-control {
	-webkit-border-radius: 0px;
	border-radius: 0px;
	border-color: #dbdbdb;
	border-width: 1px;
	border-style: solid;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.contact-section .contact-form textarea.form-control {
	max-height: 100%;
}
.contact-section .contact-form input.form-control:focus, 
.contact-section .contact-form textarea.form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.contact-section .google-map {
	width:100%;
	height:385px;
	overflow: hidden;
}
.contact-section .google-map div {
	width:100%;
}
.about-text {}
.about-img {padding: 12px;}
.about-img img {
	/* border-top-left-radius: 40px;
	border-bottom-left-radius: 40px;
	border-top-right-radius: 40px;
	border-bottom-right-radius: 150px;
	border-style: solid;
	border-bottom-width: 6px;
	border-left-width: 2px;
	border-top-width: 7px;
	border-right-width: 5px;
	border-color: #2ca8ff; */
	margin-top: 5px;
	border: 1px solid #ddd;
}
.FontStyle{
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: bold;
}

.faq_card {
	width: 100%;
}
.faq_card h5 {
	margin-top: 0px;
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: 500;
}
.faq_card .faq_desc {
	display: inherit !important;
}
.faq_card .faq_desc p {
	margin-bottom: 10px;
}

.faq_card .faq_list {
	padding: 0px 0px 0px 20px;
	list-style: disc;
}
.faq_card .faq_list li {
	line-height: 25px;
}
.faq_card .faq_list li a {
	color: #212529;
}
.faq_title {
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 22px;
	margin-top: 0px;
}
.faq_card .MuiAccordionSummary-content.Mui-expanded {
	margin: 10px 0 !important;
}
.MuiAccordion-root.faq_card.Mui-expanded {
	margin: 0px 0 !important;
}
.faq_card .MuiAccordionSummary-root.Mui-expanded {
	min-height: 48px !important;
}
.Post_metaDataCss1 ul{
  list-style: none;
  padding-left: 0px;
  overflow: hidden;
  margin-bottom: 1px;
}
.Post_metaDataCss1 ul li{
  float: left;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #aaa;
}
.Post_metaDataCss ul{
  list-style: none;
  padding-left: 0px;
  overflow: hidden;
}
.Post_metaDataCss ul li{
  float: left;
  padding-right: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #2ca8ff;
}
.min-height {
	min-height: 700px;
}
ul.item_details_list {
	list-style: none;
	padding: 0;
	margin: 0;
}
ul.item_details_list li {
	margin-bottom: 15px;
	padding: 3px;
}
ul.item_details_list li .title {
	padding-top: 0px;
	font-weight: 700;
}
ul.item_details_list li .desc {}

.map_body{
  height: 651px;
}

/*==language switcher==*/
.lang_switcher_dropdown {}
.lang_switcher_dropdown ul.lang_switcher {
	list-style: none;
	margin: 0;
	padding: 0;
	float: none;
}
.lang_switcher_dropdown ul.lang_switcher li {
	float: left;
	position: relative;
	width: 90px;
	text-align: center;
}
.lang_switcher_dropdown ul.lang_switcher li a {
	color: #0055a2;
	display: block;
	padding: 13px 10px;
	font-size: 13px;
	text-decoration: none;
	text-transform: uppercase;
	border: 1px solid #0055a2;
}
.lang_switcher_dropdown ul.lang_switcher ul li:hover > a {
	background: rgba(255, 255, 255, 0.2);
}
.lang_switcher_dropdown ul.lang_switcher li ul.lang_submenu {
	position: absolute;
	top: 100%;
	right: 0;
	width: auto;
	visibility: hidden;
	opacity: 0;
	z-index: 99999999;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}
.lang_switcher_dropdown ul.lang_switcher li:hover ul.lang_submenu {
	visibility: visible;
	opacity: 1;
}
.lang_switcher_dropdown ul.lang_switcher li ul.lang_submenu li a {
	border-top: none;
}
.sticky .lang_switcher_dropdown ul.lang_switcher li a {
	color: #fff;
	border: 1px solid #fff;
}
.desktop_lang_switcher {
	display: block;
}
.mobile_lang_switcher {
	display: none;
}

@media (min-width: 768px) and (max-width: 991px) {
	.desktop_lang_switcher {
		display: none;
	}
	.mobile_lang_switcher {
		display: block;
		margin-left: 20px;
		margin-top: 10px;
	}
	.lang_switcher_dropdown.mobile_lang_switcher ul.lang_switcher li ul.lang_submenu {
		list-style: none;
	}
	.lang_switcher_dropdown.mobile_lang_switcher ul.lang_switcher li a {
		color: #fff;
		border: 1px solid #fff;
	}
}
@media (max-width: 767px) {
	.desktop_lang_switcher {
		display: none;
	}
	.mobile_lang_switcher {
		display: block;
		margin-left: 20px;
		margin-top: 10px;
	}
	.lang_switcher_dropdown.mobile_lang_switcher ul.lang_switcher li ul.lang_submenu {
		list-style: none;
	}
	.lang_switcher_dropdown.mobile_lang_switcher ul.lang_switcher li a {
		color: #fff;
		border: 1px solid #fff;
	}	
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.desktop_lang_switcher {
		display: none;
	}
	.mobile_lang_switcher {
		display: block;
		margin-left: 20px;
		margin-top: 10px;
	}
	.lang_switcher_dropdown.mobile_lang_switcher ul.lang_switcher li ul.lang_submenu {
		list-style: none;
	}	
	.lang_switcher_dropdown.mobile_lang_switcher ul.lang_switcher li a {
		color: #fff;
		border: 1px solid #fff;
	}	
}

.card-signup .card-header {
	padding: 10px 0;
}
.card-signup .card-title {
	margin-top: 5px;
	margin-bottom: 5px;
}
.card-signup .card-footer {
	margin-bottom: 2px;
	margin-top: 0px;
	padding: 0px 0;
}
.sw-signup-padding {
	padding: 15px !important;
}

.sw_section .card .swFixedHeight {
	min-height: 370px;
	position: relative;
}
.swFixedHeight .fixed_btn {
	position: absolute;
	bottom: 0;
	left: 0;
}
.about-text .title{
  padding-top: 5px !important;
}
.align_justify {
	text-align: justify;
}
.LogClass {
   margin: 10px;
   word-wrap: break-word;
}

a.gotoBtn {
	color: #0055a2;
}
a.gotoBtn:hover {
	color: #000;
}

.sw_ToggleButtonGroup .MuiToggleButton-root:hover,
.sw_ToggleButtonGroup .MuiToggleButton-root.Mui-selected {
	color: #fff !important;
	background-color: #2ca8ff !important;
}
.sw_ToggleButtonGroup .MuiToggleButton-root {
	color: #111;
	border: 1px solid #2ca8ff !important;
}

.sw_ToggleButtonGroup2 .MuiToggleButton-root:hover,
.sw_ToggleButtonGroup2 .MuiToggleButton-root.Mui-selected {
	color: #fff !important;
	background-color: #2ca8ff !important;
}
.sw_ToggleButtonGroup2 .MuiToggleButton-root {
	color: #111;
	border: 1px solid #2ca8ff !important;
}



i.print_1{
  font-size: 18px;
  padding: 3px;
}
.red {
	color: red;
}
.msg-error .sw_error_label {
	font-weight: 700;
	font-size: 16px;
}
.msg-error textarea.error-msg-box {
	width: 100%;
	cursor: inherit !important;
	background-color: #fff !important;
	border: 1px solid #ddd;
	padding: 10px;
	margin-top: 5px;
}
.main iframe {
	width: 100%;
	min-height: 700px;
	overflow-y: hidden;
}


/*======Dashboard Report List CSS========*/
.report_list_bg {
	background-color:#626061 !important;
}
.splash_feature {
	background: rgba(255,255,255,1);
	min-height: 290px;
	color: #8d8d8d;
	display: block;
	overflow: hidden;
	padding: 20px;
	margin-bottom: 0px;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;	
}
.splash_feature .photo {
	float: left;
	line-height: 0;
	width: 46%;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;	
}
.splash_feature .photo img {
	border: 1px solid #ddd;
	padding: 3px;
}
.splash_feature .desc {
	float: left;
	margin-left: 4%;
	width: 50%;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;	
}
.splash_feature .desc .t {
	height: 194px;
}
.splash_feature .desc .t {
	display: table;
}
.splash_feature {
	color: #8d8d8d;
}
.splash_feature .desc .t .c {
	display: table-cell;
	vertical-align: middle;
}
.splash_feature .desc h5 {
	letter-spacing: 2px;
}
.splash_feature .desc p {
	margin: 0;
}

/*=======change with tooltip 09-07-2019==========*/
.reports_feature {
	width: 100%;
	background: rgba(255,255,255,1);
	min-height: 85px;
	color: #8d8d8d;
	/* display: block; */
	display: inline-block;
	padding: 7px;
	margin-bottom: 0px;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	position: relative;
}
.reports_feature .photo {
	float: left;
	line-height: 0;
	width: 23%;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;	
}
.reports_feature .photo img {
	border: 1px solid #b6afaf;
	padding: 3px;
}
.reports_feature .desc {
	float: left;
	margin-left: 3%;
	width: 73%;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;	
}
.reports_feature .desc .t {
	height: 70px;
	margin-top: 0px;
}
.reports_feature .desc .t {
	display: table;
}
.reports_feature {
	color: #8d8d8d;
}
.reports_feature .desc .t .c {
	display: table-cell;
	vertical-align: middle;
}
.reports_feature .desc h5 {
	letter-spacing: 2px;
	margin-bottom: 0px;
}
.reports_feature .desc h5 a {
	font-size: 15px;
	color: #606060;
}
.reports_feature span.reports_info_icon {
	position: absolute;
	top: 2px;
	right: 5px;
}
.reports_feature span.reports_info_icon > .fa {
	color: #73CE98;
	font-size: 20px;
}

.reports_tooltip {
  position: relative;
  display: inline-block;
}
.reports_tooltip .tooltiptext {
  visibility: hidden;
  width: 345px;
  background-color: #23AA74;
  color: #fff;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 25px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 5px;
}
.reports_tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 5px;
	right: 0;
	margin-right: -15px;
	border-width: 8px;
	border-style: solid;
	border-color: transparent transparent transparent #23AA74;
}
.reports_tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltiptext p {
	margin-bottom: 0px;
	font-size: 14px;
}
.tooltiptext h4 {
	border-bottom: 1px solid #fbf3f3;
	margin-bottom: 1px;
	padding: 0px;
	margin-top: 0px;
}
.tooltiptext h4 a {
	color: #fff;
	font-weight: bold;
	font-size: 18px;
}
.tooltiptext h4 a:hover {
	color: #fbfbfb;
}
/* Responsive css */
/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {}
/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
	.reports_feature .desc .t .c {
		vertical-align: top;
	}
}
@media (max-width: 1200px) {
	.reports_tooltip .tooltiptext {
		width: 250px;
	}
}
/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 959px) {
	.splash_feature {
		margin-bottom: 0px;
	}	
	.splash_feature .photo {
		float: none;
		width: 100%;
		margin-bottom: 20px;
	}
	.splash_feature .desc {
		float: none;
		width: 100%;
		margin: 0;
	}
	.splash_feature .desc .t {
		display: block;
		height: auto;
	}
	
	.reports_feature .photo {
		width: 100%;
	}
	.reports_feature .desc {
		margin-left: 0;
		width: 100%;
	}
	.reports_feature .desc .t {
		height: auto;
		margin-top: 5px;
	}
}
/* small mobile :320px. */
@media (max-width: 479px) {
	.splash_feature {
		margin-bottom: 20px;
	}		
	.splash_feature .photo {
		float: none;
		width: 100%;
		margin-bottom: 20px;
	}
	.splash_feature .desc {
		float: none;
		width: 100%;
		margin: 0;
	}
	.splash_feature .desc .t {
		display: block;
		height: auto;
	}
	.reports_feature .photo {
		width: 100%;
	}
	.reports_feature .desc {
		margin-left: 0;
		width: 100%;
	}
	.reports_feature .desc .t {
		height: auto;
		margin-top: 5px;
	}		
	.reports_tooltip .tooltiptext {
		width: 170px;
	}
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.splash_feature {
		margin-bottom: 20px;
	}		
	.splash_feature .photo {
		float: none;
		width: 100%;
		margin-bottom: 20px;
	}
	.splash_feature .desc {
		float: none;
		width: 100%;
		margin: 0;
	}
	.splash_feature .desc .t {
		display: block;
		height: auto;
	}
}
/*======End of Dashboard Report List CSS========*/


/*======GTIN Search Box CSS========*/
.search_card {
	width: 100%;
	display: inline-flex;
}
.search_card .search_control_group {
	width: auto;
	display: inline-flex;
	margin: 0px 6px;	
}
.search_card .search_control_group .search_control_label {
	margin: 0px;
	float: left;
	color: #626262;
	padding: 10px 6px 10px 0px;
	font-size: 12px;
	font-weight: 700;
}
.search_card .search_control_group .search_form_control {
	margin-bottom: 0px;
	padding: 8px 6px;
	float: left;
	font-size: 14px !important;
	border: 1px solid #bbb !important;	
}

.swDisabled {
	position: relative !important;
}
.swDisabled::after {
	content: "";
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	top: 0px !important;
	left: 0px !important;
	background: rgba(244, 244, 244, 0.5) !important;
}

.redtextcolor {
    color: rgba(255, 0, 0, 0.957);
	font-weight: 700; 
  }
  .masterredtextcolor {
    color: rgba(255, 0, 0, 0.957);
	font-weight: 700; 
  }
  .greenColor{
	color:green;
	font-weight: bold;
  }
  .redColor{
	color:red;
	font-weight: bold;
  }


  .widget_mfn_recent_comments ul li {
	padding-bottom: 15px;
	margin-bottom: 0 !important;
	background: url(../img/recent_comments.png) no-repeat 4px top;
	padding-left: 40px;
	position: relative;
	list-style: none !important;
  }

  .widget_mfn_recent_comments ul li::after{
	color: #008751;
  }

  .widget_mfn_recent_comments ul li::after {
	width: 15px;
	height: 15px;
  }

  ::after, ::before {
	box-sizing: border-box;
  }

  .widget_mfn_recent_comments ul li::after {
	content: "";
	width: 7px;
	height: 7px;
	border-width: 4px;
	border-style: solid;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 11px;
	display: block;
	z-index: 1;
  }

  .activity_area {
    width: 100%;
    padding: 10px;
	margin-bottom:20px;
}

.ActivityList {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.ActivityList:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.ActivityList p {
    width: 100%;
    margin-bottom: 0px !important;
    padding: 0px;
	font-size: 100%;
}	
.dateClass {
    float: left;
    font-weight: 700;
    padding: 0;
}
.userClass {
    float: right;
    padding: 0;
    text-align: right;
}

.qtitle-ctm {
    display: block;
    font-weight: 700 !important;
    margin-bottom: 5px;
    width: 100%;
}

.qtitle-ctm {
	display: block;
	font-weight: 700 !important;
	margin-bottom: 5px;
	width: 100%;
  }
  .qtitle-ctm {
  font-size: 12px;
	letter-spacing: 1px;
	color: #5f6d78;
  }

  .dateClass {
	font-weight: bold;
	font-size: 12px;
	padding: 0;
	float: left;
  }

  span.mosScale.stockout {
	background: #f95053;
	border: 3px solid #d7191c;
  }

  span.mosScale {
	margin-right: 3px;
	width: 12px !important;
	height: 12px !important;
	display: inline-block;
	border-radius: 100%;
  }
.alignCenter{
	text-align: center !important;
}
.bdcolor{
	background-color: rgb(244, 244, 244);
}

.dislpy{
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: flex-start;
	align-content: flex-start;

}
.EororData{
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: flex-start;
	align-content: flex-start;

}

.MuiTypography_h1 {
	font-size: 8rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	line-height: 1.167;
	letter-spacing: -0.01562em;
  }
.paddingBottom {
	padding-bottom: 24px !important;
}

.attachment_view_card {
	width: 200px;
	height: 200px;
	background: #fefefe;
	border: 1px solid #ddd;
	position: relative;
	margin-top: 20px;
	border-radius: 10px;
}
.attachment_view_card .view_icon {
	padding: 40px;
}
.attachment_view_card .view_icon svg {
	width: 100% !important;
	height: 100% !important;
	color: #e61b23;
	cursor: pointer;
}
.attachment_view_card button.delete_icon {
	background: #ff0b0b;
	color: #fff;
	font-size: 20px;
	border-radius: 50%;
	line-height: 30px;
	width: 30px;
	height: 30px;
	text-align: center;
	padding: 0 !important;
	min-width: 30px;
	text-transform: none;
	position: absolute;
	top: -10px;
	right: -10px;
}

.up_image_card {
	width: 110px;
	height: auto;
	position: relative;
	margin-top: 20px;
}
.up_image_card button.delete_icon {
	background: #ff0b0b;
	color: #fff;
	font-size: 20px;
	border-radius: 50%;
	line-height: 25px;
	width: 25px;
	height: 25px;
	text-align: center;
	padding: 0 !important;
	min-width: 25px;
	text-transform: none;
	position: absolute;
	top: -10px;
	right: -10px;
}
.up_image_card .imagecard {
	width: 100%;
	border: 1px solid #ddd;
	border-radius: 10px;
	background: #fefefe;
}
.up_image_card .imagecard img {
	width: 100%;
}

.theme_color {
	color: #3f51b5;
}
.black_color {
	color: #5e5e5e;
}
.pdf_color {
	color: #e61b23;
}
.legentId{
	font-size: 15px !important;
	text-transform: lowercase;
}

/*login_signup*/
.page-header.h_PageHeader {
	min-height: auto !important;
	max-height: none !important;
}
.page-header .login_signup_content {
	margin-top: 130px !important;
	margin-bottom: 150px !important;
}
.login_signup_card {
	background: #bbe3ff;
	color: #2c2c2c;
	overflow: hidden;
	border-radius: 10px;
	-webkit-box-shadow: 0px 15px 66px 5px rgba(239, 239, 239, 0.5);
	-moz-box-shadow: 0px 15px 66px 5px rgba(239, 239, 239, 0.5);
	box-shadow: 0px 15px 66px 5px rgba(239, 239, 239, 0.5);
}
.login_signup_card .image_content_card {
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
}
.login_signup_card .image_content_card.h_login {
	min-height: 470px;
}
.login_signup_card .image_content_card.h_signup {
	min-height: 500px;
}
.login_signup_card .image_content_card .content_card {
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 9999;
	padding: 40px;
}
.login_signup_card .image_content_card .content_card h3 {
	color: #2c2c2c;
	font-weight: 700;
	margin-bottom: 10px;
	font-size: 20px;	
}
.login_signup_card .image_content_card .content_card p {
	color: #2c2c2c;
	font-size: 14px;
	line-height: 2;
}
.login_signup_card .image_content_card .image_card {
	width: 400px;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 0;
	right: 0;
	margin: 0 auto;
}
.login_signup_card .image_content_card .image_card img {
	width: 100%;
}
.login_signup_card .login_signup_form {
	width: 100%;
	height: 100%;
	padding: 40px 60px;
	background-color: #fff;
}
.login_signup_card .login_signup_form h3 {
	color: #2c2c2c;
	font-weight: 700;
	margin-bottom: 5px;
	font-size: 20px;
}
.login_signup_card .login_signup_form p {
	color: #2c2c2c;
	font-size: 14px;
}

.login_signup_form .input-group .form-control:last-child {
	border-left: 1px solid #E3E3E3 !important;
}
.login_signup_form .input-group.input-group-focus .form-control:last-child,
.login_signup_form .input-group.input-group-focus .input-group-prepend .input-group-text {
	border-left: 1px solid #2CA8FF !important;
}
.login_signup_form .form-control:focus {
	border: 1px solid #2CA8FF !important;
}
.login_signup_form .input-group-focus .input-group-text {
	border-color: #2CA8FF !important;
}
.login_signup_form .footer_card ul.login_signup_list {
	padding: 0;
	margin: 0;
	list-style-type: none;
}
.login_signup_form .footer_card ul.login_signup_list li {
	padding: 0;
	margin: 0;
}
.login_signup_form .footer_card ul.login_signup_list li a {
	color: #0055a2;
}

.login_signup_form .input-group .input-group-prepend .input-group-text.is-invalid, 
.login_signup_form .form-group .input-group-prepend .input-group-text.is-invalid {
	border-left: 1px solid #dc3545;
}
.login_signup_form .input-group-text.is-invalid {
	border: 1px solid #dc3545;
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;	
}

/* .login_signup_form .input-group > .custom-select:not(:last-child), 
.login_signup_form .input-group > .form-control:not(:last-child) {
	border-top-right-radius: 30px !important;
	border-bottom-right-radius: 30px !important;
} */

.dhx_container_inner {
	height: 500px;
}
.dhx_settings-button-wrap--readonly{
	display: none !important;
}

.dhx_sample-controls1 {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-height: 33px;
	height: 35px;
    border-bottom: 1px solid #DFDFDF;
    font-family: Roboto,Arial,Tahoma,Verdana,sans-serif;
    font-size: 14px;
}

/*======Reporting Rate======*/
.reportingRate {
	background-color: #2ca8ff;
	border-radius: 4px;
	padding: 7px 0;
	width: 100%;
	overflow: hidden;
	text-align: center;
	margin: 10px 0;
}
.report_title {
	font-weight: 700;
	color: #fff;
	margin: 0px 10px;
}
.report_title_val {
	color: #fff;
	margin: 0px 5px;
	font-weight: 500;
}
.bgPrimaryBlock {
	width: 100%;
	text-align: center;
	padding: 62px 0;
}
.bgPrimaryBlock .text-xlg {
	width: 100%;
	display: inline-block;
	font-weight: bold;
	margin-bottom: 10px;
}
.bgPrimaryBlock .text-bg {
	display: inline-block;
	width: 100%;
}

.blocks_of_info {
	padding: 0;
	width: 100%;
	overflow: hidden;
	text-align: center;
}
.blocks_of_info .blocks_of_info_item {
	width: 25%;
	float: left;
	padding: 5px;
}
.blocks_of_info_item .sigle_info {
	width: 100%;
	text-decoration: none;
	border-radius: 0px !important;
	margin-bottom: 0px;
	overflow: hidden;
}
.blocks_of_info_item .sigle_info span{
	font-size: 26px;
	margin-bottom: 10px;
	color: #fff;
	width: 100%;
	font-weight: normal;
}
.blocks_of_info_item .sigle_info p {
	font-size: 12px;
	margin-bottom: 7px;
	color: #fff;
	width: 100%;
}
/* Responsive css */
@media (max-width: 479px) {
	.blocks_of_info_item {
		width: 100% !important;
	}
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.blocks_of_info_item {
		width: 100% !important;
	}	
}

.leaflet-pane {
	z-index: 1 !important;
}
.leaflet-top, 
.leaflet-bottom {
	z-index: 1 !important;
}

.sw_confirm_btn {
  background-color: #3f51b5;
}
.swal-button.sw_confirm_btn:not([disabled]):hover {
    background-color: #303f9f;
}
.sw_label_group {
	width: 100%;
}
.sw_label_group .label {
	color: rgba(0, 0, 0, 0.54);
	font-weight: 400;
	line-height: 1;
	font-size: 11px !important;
}
.sw_label_group .code {
	font-size: 24px;
	font-weight: 700;
}
.sw_label_group .name {
	font-size: 16px;
	font-weight: 700;
}

